import {Injectable} from '@angular/core';
import {HttpService} from '@core/backend/common/api/http.service';
import {Observable} from 'rxjs';
import {
    APIResponse,
    Filter,
    FilterFieldOption,
    FilterFieldOptionRequest,
    FilterFieldOptionResponse,
    GenericListRequest,
    PresignedURL,
    SimpleMultiSeries,
    SimpleSeries,
    TableFilterConfig,
    YearQuarterPair,
} from '@core/interfaces/system/system-common';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {
    ChangeHistoryDto,
    InspectionChartComparisonRequest,
    InspectionResultCorrectionDto,
    InspectionResultDto,
    InspectionResultReviewDto,
} from '@core/interfaces/engin/maintenance-planning/maintenance-planning';
import {InspectionResultFullDto} from '@core/interfaces/engin/maintenance-planning/form-visualization';
import {HttpParams} from '@angular/common/http';
import {AssetColumn} from '@core/interfaces/common/asset';

@Injectable()
export class MaintenancePlanningApi {
    private readonly prefix = 'maintenance';

    constructor(private api: HttpService) {}

    // Inspection Details
    findOneDto(resultId: number): Observable<InspectionResultDto> {
        return this.api.get(`${this.prefix}/dto/${resultId}`);
    }

    findOneFullDto(resultId: number): Observable<InspectionResultFullDto> {
        return this.api.get(`${this.prefix}/dto-full/${resultId}`);
    }

    getChangeHistory(resultId: number): Observable<ChangeHistoryDto[]> {
        return this.api.get(`${this.prefix}/history/${resultId}`);
    }

    submitReview(resultId: number, req: InspectionResultReviewDto): Observable<InspectionResultDto> {
        return this.api.post(`${this.prefix}/review/${resultId}`, req);
    }

    submitCorrection(resultId: number, req: InspectionResultCorrectionDto): Observable<InspectionResultFullDto> {
        return this.api.post(`${this.prefix}/correction/${resultId}`, req);
    }

    findLatestInspectionResult(resultId: number): Observable<APIResponse<InspectionResultDto>> {
        return this.api.get(`${this.prefix}/latest/${resultId}`);
    }

    getPresignedUrl(fileName: string, fileFormat: string): Observable<APIResponse<PresignedURL>> {
        return this.api.post(`${this.prefix}/presigned-url`, {fileName, fileFormat});
    }

    upload(url: string, fileData: File): Observable<any> {
        return this.api.uploadS3(`${url}`, fileData, {
            reportProgress: true,
            observe: 'events',
            headers: {
                'Content-Disposition': 'attachment',
                'Content-Type': fileData.type,
            },
        });
    }

    // Options
    getFilterOptions(): Observable<APIResponse<TableFilterConfig[]>> {
        return this.api.get(`${this.prefix}/options/filters`);
    }

    getFilterFieldOptions(req: FilterFieldOptionRequest): Observable<APIResponse<FilterFieldOptionResponse>> {
        return this.api.post(`${this.prefix}/options/filters/field`, req);
    }

    // Inspection List
    listDto(): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/dto/list`);
    }

    listDtoCsv(filterParams: Filter[]): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.post(`${this.prefix}/dto/list/csv`, {filterParams});
    }

    // Inspection Dashboard
    getGroupOptions(): Observable<APIResponse<AssetColumn[]>> {
        return this.api.get(`${this.prefix}/options/groups`);
    }

    listInspectionAssetDto(req: GenericListRequest): Observable<DataSource> {
        return this.api.postServerDataSource(`${this.prefix}/dto-asset/list`, req);
    }

    getInspectionsByStatus(req: GenericListRequest): Observable<APIResponse<SimpleSeries<string, number>>> {
        return this.api.post(`${this.prefix}/chart/status`, req);
    }

    getInspectionsComparison(
        req: InspectionChartComparisonRequest,
    ): Observable<APIResponse<SimpleMultiSeries<string>>> {
        return this.api.post(`${this.prefix}/chart/comparison`, req);
    }

    // Asset Registry > Maintenance
    getInspectionsTimelineByAsset(
        req: GenericListRequest,
    ): Observable<APIResponse<SimpleSeries<YearQuarterPair, number>>> {
        return this.api.post(`${this.prefix}/chart/timeline`, req);
    }

    getInspectionsSummaryByAsset(req: GenericListRequest): Observable<APIResponse<SimpleSeries<string, number>[]>> {
        return this.api.post(`${this.prefix}/chart/summary`, req);
    }

    listDtoByAssetId(assetId: string): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/dto/list?filterByassetId_EQUAL=${assetId}`);
    }
}
